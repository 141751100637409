import React from 'react';
import Box from '@mui/material/Box';

import type { HttpUrl } from 'src/shared/types';

type Props = {
    background: {
        type: 'image';
        url: HttpUrl;
    } | {
        type: 'empty';
    };
    children?: React.ReactNode;
};

export const BlurredRestaurantImageArea: React.FC<Props> = ({ background, children }) => {
    return (
        <Box
            sx={{
                position: 'relative',
                borderRadius: 5,
                width: '100%',
                height: '100%',
                ...(background.type === 'empty' ? {
                    backgroundColor: 'neutral800.main',
                } : {}),
                boxShadow: 'inset 0 0 40px 20px rgba(0,0,0,0.7)',
            }}
        >
            {background.type === 'image' && (
                <Box
                    sx={{
                        backgroundImage: `url('${background.url}')`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundClip: 'padding-box',
                        backgroundOrigin: 'border-box',
                        borderRadius: 5,
                        position: 'relative',
                        filter: 'blur(20px)',
                        clipPath: 'inset(0 round 20px)',
                        height: '100%',
                    }}
                />
            )}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    padding: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 5,
                }}
            >
                {children}
            </Box>
        </Box>
    );
};
