import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/system/useMediaQuery';
import { BlurredRestaurantImageArea } from 'restaurant-v3/src/components/BlurredRestaurantImageArea';

import type { Theme } from '@mui/material';
import type { NonEmptyString } from 'src/shared/types';

type Props = {
    background: Parameters<typeof BlurredRestaurantImageArea>[0]['background'];
    description: NonEmptyString;
};

export const HeroDescription: React.FC<Props> = ({ background, description }) => {
    const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
    const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.only('sm'));
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.only('md'));
    const isLg = useMediaQuery((theme: Theme) => theme.breakpoints.only('lg'));
    return (
        <BlurredRestaurantImageArea
            background={background}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    minHeight: '300px',
                }}
            >
                <Typography
                    sx={{
                        color: 'white.main',
                        // Note(samuel) - custom CSS bullshit to prevent text overflow - discussed with Matěj
                        ...((function () {
                            if (description.length <= 255) {
                                switch (true) {
                                    case isXs:
                                        return {
                                            paddingLeft: 2.5,
                                            paddingRight: 2.5,
                                            fontSize: '1.1rem',
                                            lineHeight: '1.32rem',
                                        };
                                    case isSm:
                                        return {
                                            paddingLeft: 6.25,
                                            paddingRight: 6.25,
                                            fontSize: '1.25rem',
                                            lineHeight: '2rem',
                                        };
                                    default:
                                        return {
                                            paddingLeft: 11.25,
                                            paddingRight: 11.25,
                                            fontSize: '1.25rem',
                                            lineHeight: '2rem',
                                        };
                                }
                            }
                            switch (true) {
                                case isXs:
                                    return {
                                        paddingLeft: 2.5,
                                        paddingRight: 2.5,
                                        fontSize: '0.8rem',
                                        lineHeight: '1.2rem',
                                    };
                                case isSm:
                                    return {
                                        paddingLeft: 6.25,
                                        paddingRight: 6.25,
                                        fontSize: '1.1rem',
                                        lineHeight: '1.5rem',
                                    };
                                case isMd:
                                    return {
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                        fontSize: '1rem',
                                        lineHeight: '1.5rem',
                                    };
                                case isLg:
                                    return {
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                        fontSize: '1.2rem',
                                        lineHeight: '1.8rem',
                                    };
                                // Xl or greater
                                default:
                                    return {
                                        paddingLeft: 11.25,
                                        paddingRight: 11.25,
                                        fontSize: '1.25rem',
                                        lineHeight: '1.75rem',
                                    };
                            }
                        })()),
                    }}
                    variant="h5"
                >
                    {description}
                </Typography>
            </Box>
        </BlurredRestaurantImageArea>
    );
};
