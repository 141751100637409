import React from 'react';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { NonEmptyStringSchema } from 'src/shared/types';
import { MenuItemSlideshow } from 'restaurant-v3/src/components/MenuItemSlideshow';

import { TakeawayOrderQrCode } from './TakeawayOrderQrCode';
import { HeroSectionSpacing } from './consts';
import { HeroPreviewImage } from './HeroPreviewImage';
import { HeroDescription } from './HeroDescription';

import type { HeroSectionProps } from './types';
import type { Theme } from '@mui/material';

export const HeroSectionBrowser: React.FC<HeroSectionProps> = ({
    takeaway,
    restaurantDescription,
    menuItemSlideshow,
    restaurantImage,
    restaurantId,
    restaurantName,
}) => {
    const shouldRenderRightSide = restaurantDescription !== null && menuItemSlideshow !== null;
    const isXl = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                height: '1000px',
                gap: HeroSectionSpacing,
            }}
        >
            {restaurantImage.url !== null && (
                <Box
                    sx={{
                        display: 'flex',
                        flexBasis: '50%',
                        flexDirection: 'column',
                        gap: HeroSectionSpacing,
                        height: '100%',
                        ...(shouldRenderRightSide ? {
                            maxWidth: '50%',
                        } : {
                            flexGrow: 1,
                            maxWidth: '100%',
                        }),
                    }}
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                            flexBasis: '80%',
                        }}
                    >
                        <HeroPreviewImage
                            animationEnabled={restaurantImage.animationEnabled}
                            imageUrl={restaurantImage.url}
                            key="preview"
                        />
                    </Box>
                    {takeaway.isEnabled && (
                        <Box
                            sx={{
                                flexBasis: '20%',
                                maxHeight: '20%',
                                minHeight: '8rem',
                            }}
                        >
                            <TakeawayOrderQrCode
                                restaurantId={restaurantId}
                                restaurantName={restaurantName}
                                takeawayUrl={takeaway.url}
                            />
                        </Box>
                    )}
                </Box>
            )}
            {shouldRenderRightSide && (
                <Box
                    sx={{
                        display: 'flex',
                        flexBasis: '50%',
                        flexDirection: 'column',
                        gap: HeroSectionSpacing,
                        height: '100%',
                        ...(restaurantImage.url === null ? {
                            // Grow to full width if restaurant image is missing
                            flexGrow: 1,
                        } : {}),
                    }}
                >
                    {restaurantDescription !== null && (
                        <Box
                            sx={{
                                flexBasis: '40%',
                                // Note(samuel) - apply flex grow to ensure that it expands if menu item slideshow is missing
                                flexGrow: 1,
                            }}
                        >
                            <HeroDescription
                                background={restaurantImage.url !== null ? {
                                    type: 'image',
                                    url: restaurantImage.url,
                                } : {
                                    type: 'empty',
                                }}
                                description={NonEmptyStringSchema.parse(restaurantDescription)}
                                key="description"
                            />
                        </Box>
                    )}
                    {menuItemSlideshow !== null && menuItemSlideshow.length > 0 && (
                        <Box
                            sx={{
                                flexBasis: '60%',
                                // Note(samuel) - apply flex grow to ensure that it expands if restaurant description missing
                                flexGrow: 1,
                            }}
                        >
                            <MenuItemSlideshow
                                items={menuItemSlideshow}
                                key="slideshow"
                                // Note - image optimization
                                slideshowImageSize={(function () {
                                    if (restaurantImage === null) {
                                        // If Restaurant image is missing, then the slideshow pictures take up 100% width of container space
                                        return '100vw';
                                    }
                                    if (isXl) {
                                        return '33vw';
                                    }
                                    return '50vw';
                                })()}
                            />
                        </Box>
                    )}
                    {takeaway.isEnabled && restaurantImage.url === null && (
                        <Box
                            sx={{
                                flexBasis: '20%',
                                maxHeight: '20%',
                                minHeight: '8rem',
                            }}
                        >
                            <TakeawayOrderQrCode
                                restaurantId={restaurantId}
                                restaurantName={restaurantName}
                                takeawayUrl={takeaway.url}
                            />
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};
