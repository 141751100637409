import Box from '@mui/material/Box';
import React from 'react';

type Props = {
    children?: React.ReactNode;
    transitionBackgroundSize: number;
};

export const HorizontalScrollArea: React.FC<Props> = ({ children, transitionBackgroundSize }) => {
    return (
        <Box
            sx={{
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 33%, rgba(255,255,255,1) 100%)',
                    height: '100%',
                    position: 'absolute',
                    right: 0,
                    width: `${transitionBackgroundSize}px`,
                    zIndex: 10,
                }}
            />
            <Box
                sx={{
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    scrollSnapType: 'x mandatory',
                    scrollBehavior: 'smooth',
                    scrollbarWidth: 'thin',
                    // Note(samuel) - extra gap for scrolling effect
                    display: 'flex',
                    gap: 1,
                    flexWrap: 'nowrap',
                    paddingRight: `${transitionBackgroundSize}px`,
                }}
            >
                {children}
            </Box>
        </Box>
    );
};
