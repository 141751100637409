'use client';

import React, { useState } from 'react';
import Image from 'next/image';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { s3ImageLoader } from 'restaurant-v3/src/utils/s3ImageLoader';
import SlideshowArrowLeft from 'restaurant-v3/src/icons/slideshow-arrow-left.svg';
import SlideshowArrowRight from 'restaurant-v3/src/icons/slideshow-arrow-right.svg';

import type { APIv1RestaurantWeb } from 'src/shared-interface/ApiRestaurantWebInterface';

type MenuItem = Extract<APIv1RestaurantWeb['ENDPOINT']['GET:/menu-item/upsell-items-slideshow']['result']['result'], {success: true}>['payload']['menuItems'][number];

type SlideshowImageSize = '33vw' | '50vw' | '100vw';

type MenuItemProps = {
    menuItem: MenuItem;
    isActive?: boolean;
    onClick?: () => void;
    slideshowImageSize: SlideshowImageSize;
};

const MenuItem: React.FC<MenuItemProps> = ({ menuItem, isActive, onClick, slideshowImageSize }) => {
    return (
        <Box
            onClick={onClick}
            sx={{
                width: '100%',
                height: '100%',
                borderRadius: 5,
                position: 'absolute',
                cursor: onClick ? 'pointer' : 'default',
                zIndex: 20,
            }}
        >
            <Image
                alt={menuItem.name}
                fill
                loader={s3ImageLoader}
                objectFit="contain"
                sizes={slideshowImageSize}
                src={menuItem.image!}
                style={{
                    // NOTE(samuel) - next/image border radius is in `pxs`
                    borderRadius: 20,
                    objectFit: 'cover',
                    opacity: isActive ? 1 : 0,
                    top: 0,
                    transition: 'opacity 0.75s ease-in-out',
                }}
            />
            <Box
                sx={{
                    position: 'relative',
                    // Move this to bottom
                    // Inspiration - https://stackoverflow.com/questions/17521178/css-position-relative-stick-to-bottom
                    top: '100%',
                    transform: 'translateY(-100%)',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0.5,
                    paddingY: 2,
                    paddingX: 3,
                    color: 'white.main',
                    transition: 'opacity 0.75s ease-in-out',
                    zIndex: 10,
                    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)',
                    borderRadius: 5,
                    opacity: isActive ? 1 : 0,
                }}
            >
                <Typography
                    fontWeight="bold"
                    sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {menuItem.name}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: 0.5,
                    }}
                >
                    <Typography
                        component="span"
                        sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {menuItem.description}
                    </Typography>
                    <Typography
                        component="span"
                        sx={{
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {/* TODO localize currency */}
                        {`${menuItem.price} CZK`}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

type Props = {
    items: MenuItem[];
    slideshowImageSize: SlideshowImageSize;
};

// Z-index
// 1. Arrow icons - 30
// 2. Clickable area - 20
// 3. Menu Item description - 10
//    * Includes opacity transition
// 4. Background
//    * Includes opacity transition
export const MenuItemSlideshow: React.FC<Props> = ({ items, slideshowImageSize }) => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const hasMultipleItems = items.length > 1;
    const prevMenuItem = () => setActiveItemIndex((prevActiveItemIndex) => (prevActiveItemIndex - 1 + items.length) % items.length);
    const nextMenuItem = () => setActiveItemIndex((prevActiveItem) => (prevActiveItem + 1) % items.length);
    return (
        <Box
            sx={{
                borderRadius: 5,
                cursor: 'pointer',
                position: 'relative',
                width: '100%',
                height: '100%',
            }}
        >
            {items.map((menuItem, index) => (
                <MenuItem
                    isActive={index === activeItemIndex}
                    key={menuItem.id}
                    menuItem={menuItem}
                    onClick={nextMenuItem}
                    slideshowImageSize={slideshowImageSize}
                />
            ))}
            {hasMultipleItems && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 2,
                    }}
                >
                    <button
                        onClick={prevMenuItem}
                        style={{
                            all: 'unset',
                            cursor: 'pointer',
                            fill: '#ffffff',
                            borderRadius: '50%',
                            zIndex: 30,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                bgcolor: 'rgba(255,255,255,0.2)',
                                borderRadius: '50%',
                            }}
                        >
                            <Image
                                alt="Left arrow"
                                src={SlideshowArrowLeft}
                            />
                        </Box>
                    </button>
                    <button
                        onClick={nextMenuItem}
                        style={{
                            all: 'unset',
                            cursor: 'pointer',
                            fill: '#ffffff',
                            borderRadius: '50%',
                            zIndex: 30,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                bgcolor: 'rgba(255,255,255,0.2)',
                                borderRadius: '50%',
                            }}
                        >
                            <Image
                                alt="Right arrow"
                                src={SlideshowArrowRight}
                            />
                        </Box>
                    </button>
                </Box>
            )}
        </Box>
    );
};
