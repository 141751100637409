import React from 'react';
import Box from '@mui/material/Box';
import Image from 'next/image';
import { s3ImageLoader } from 'restaurant-v3/src/utils/s3ImageLoader';

import type { HttpUrl } from 'src/shared/types';

type Props = {
    imageUrl: HttpUrl;
    animationEnabled?: boolean;
};

export const HeroPreviewImage: React.FC<Props> = ({ imageUrl, animationEnabled }) => {
    return (
        <Box
            sx={{
                borderRadius: 5,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                overflow: 'clip',
                ...(animationEnabled ? {
                    '@keyframes inspectImage': {
                        '0%, 100%': {
                            transform: 'translateX(100px)',
                        },
                        '50%': {
                            transform: 'translateX(-100px)',
                        },
                    },
                } : {}),
            }}
        >
            <Image
                alt="Restaurant Preview"
                // Note - hardcoded size for browser
                height={800}
                loader={s3ImageLoader}
                sizes="100vw 50vw"
                src={imageUrl}
                style={{
                    maxHeight: '100%',
                    // Note - hardcoded extra 200px for animation
                    minWidth: 'calc(100% + 200px)',
                    // Note - border radius in px
                    borderRadius: 20,
                    objectFit: 'cover',
                    ...(animationEnabled ? {
                        animation: 'inspectImage 64s linear infinite',
                    } : {}),
                }}
                width={1800}
            />
        </Box>
    );
};
