import React from 'react';
import Box from '@mui/material/Box';
import { NonEmptyStringSchema } from 'src/shared/types';
import { MenuItemSlideshow } from 'restaurant-v3/src/components/MenuItemSlideshow';

import { HeroSectionSpacing } from './consts';
import { HeroDescription } from './HeroDescription';
import { HeroPreviewImage } from './HeroPreviewImage';
import { TakeawayOrderMobileButton } from './TakeawayOrderMobileButton';

import type { HeroSectionProps } from './types';

export const HeroSectionMobile: React.FC<HeroSectionProps> = ({
    takeaway,
    restaurantDescription,
    menuItemSlideshow,
    restaurantImage,
    restaurantId,
    restaurantName,
}) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            gap: HeroSectionSpacing,
        }}
    >
        {restaurantImage.url !== null && (
            <Box
                sx={{
                    // Note - don't go over 800px, otherwise hero preview image will be ugly
                    height: 'min(max(66vh, 66vw), 800px)',
                }}
            >
                <HeroPreviewImage
                    animationEnabled={restaurantImage.animationEnabled}
                    imageUrl={restaurantImage.url}
                    key="preview"
                />
            </Box>
        )}
        {restaurantDescription !== null && (
            <Box
                sx={{
                    height: 'max(33vh, 33vw)',
                }}
            >
                <HeroDescription
                    background={restaurantImage.url !== null ? {
                        type: 'image',
                        url: restaurantImage.url,
                    } : {
                        type: 'empty',
                    }}
                    description={NonEmptyStringSchema.parse(restaurantDescription)}
                    key="description"
                />
            </Box>
        )}
        {menuItemSlideshow !== null && menuItemSlideshow.length > 0 && (
            <Box
                sx={{
                    height: 'max(33vh, 33vw)',
                }}
            >
                <MenuItemSlideshow
                    items={menuItemSlideshow}
                    key="slideshow"
                    slideshowImageSize="100vw"
                />
            </Box>
        )}
        {takeaway.isEnabled && (
            <Box>
                <TakeawayOrderMobileButton
                    restaurantId={restaurantId}
                    restaurantName={restaurantName}
                    takeawayUrl={takeaway.url}
                />
            </Box>
        )}
    </Box>
);
