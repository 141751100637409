import Box from '@mui/material/Box';
import React from 'react';
import Typography from '@mui/material/Typography';

import { poweredByQerko } from './poweredByQerko';

import type { TakeawayComponentProps } from './types';

export const TakeawayOrderQrCode: React.FC<TakeawayComponentProps> = ({ restaurantName, takeawayUrl }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                gap: 1.75,
                height: '100%',
                padding: 1.75,
                borderRadius: 5,
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'neutral200.main',
            }}
        >
            <img
                alt={
                    `QR Code for restaurant ${restaurantName}`}
                src={`${process.env.NEXT_PUBLIC_QR_CODE_GENERATOR_URL}/?theme=logo&scale=2&code=${encodeURIComponent(takeawayUrl)}`}
            />
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: 1,
                }}
            >
                <Box>
                    <Typography
                        variant="h5"
                    >
                        Jídlo s sebou?
                    </Typography>
                    <Typography
                        variant="h5"
                    >
                        Objednejte si přes Qerko.
                    </Typography>
                </Box>
                {poweredByQerko}
            </Box>
        </Box>
    );
};
