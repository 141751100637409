'use client';

import React from 'react';
import { format } from 'date-fns';
import MuiLink from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDailyLunchMenuQuery } from 'restaurant-v3/src/hooks/queries/useDailyLunchMenuQuery';
import { formatTimeStringHourMinute } from 'restaurant-v3/src/utils/formatTimeString';
import { getHref } from 'restaurant-v3/src/utils/navigation';
import { daysOfWeek } from 'restaurant-v3/src/constants/tmpLocalization';
import { dailyMenuId } from 'restaurant-v3/src/constants/scrollableElementIds';

import { VisualButton } from './VisualButton';

import type { NonEmptyString64 } from 'src/shared/types';
import type { FC } from 'react';
import type { APIv1RestaurantWeb } from 'src/shared-interface/ApiRestaurantWebInterface';

type Props = {
    restaurantDomain: NonEmptyString64;
    date: Date;
};

export const DailyMenu: FC<Props> = ({ restaurantDomain, date }) => {
    const { data: restaurantDailyMenuResult } = useDailyLunchMenuQuery({ date, restaurantDomain });

    if (
        !restaurantDailyMenuResult.result.success ||
        !restaurantDailyMenuResult.result.payload.lunchDailyMenuData ||
        restaurantDailyMenuResult.result.payload.lunchDailyMenuData.items.length === 0
    ) {
        return null;
    }

    return (
        <RawDailyMenu
            date={date}
            lunchDailyMenuData={restaurantDailyMenuResult.result.payload.lunchDailyMenuData}
            restaurantDomain={restaurantDomain}
        />
    );
};

type RawProps = {
    date: Date;
    lunchDailyMenuData: NonNullable<Extract<APIv1RestaurantWeb['ENDPOINT']['GET:/menu/daily-lunch-menu']['result']['result'], { success: true }>['payload']['lunchDailyMenuData']>;
    restaurantDomain: NonEmptyString64;
    shouldDisplayMenuName?: boolean;
};

const RawDailyMenu: FC<RawProps> = ({ date, lunchDailyMenuData, shouldDisplayMenuName, restaurantDomain }) => {
    return (
        <Box
            component="section"
            id={dailyMenuId}
            sx={{
                paddingY: 6,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 6,
            }}
        >
            <Box>
                <Typography
                    variant="h2"
                >
                    {`${daysOfWeek[date.getDay()]} ${format(date, 'd.L.')}`}
                </Typography>
                <Typography
                    sx={{
                        color: 'neutral500.main',
                        textAlign: 'center',
                        fontSize: 1,
                    }}
                >
                    {`${
                        shouldDisplayMenuName ? `${lunchDailyMenuData.name} ` : ''
                    }${
                        formatTimeStringHourMinute(lunchDailyMenuData.openFrom)
                    }-${
                        formatTimeStringHourMinute(lunchDailyMenuData.openTo)
                    }`}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                    minWidth: 'calc(min(90vw, 500px))',
                    color: 'neutral900.main',
                }}
            >
                {lunchDailyMenuData.items.map(({ id, name, description, price }) => (
                    <Box key={id}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                lineHeight: 1.5,
                                gap: 2,
                            }}
                        >
                            <b>
                                {name}
                            </b>
                            <Box>
                                {/* TODO - price localization */}
                                {`${price} CZK`}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                color: 'neutral500.main',
                                lineHeight: 1.5,
                            }}
                        >
                            {description}
                        </Box>
                    </Box>
                ))}
            </Box>
            <MuiLink href={getHref('/menu', restaurantDomain)}>
                <VisualButton label="Zobrazit celé menu" variant="primaryInverted" />
            </MuiLink>
        </Box>
    );
};
