import {
    useMutation, useSuspenseQuery,
} from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { createInlineError } from 'src/shared/errors';
import { fetchApi, prepareApiCall } from 'restaurant-v3/src/utils/api';
import { logger } from 'common/utils/logger';

import type { APIv1RestaurantWeb } from 'src/shared-interface/ApiRestaurantWebInterface';
import type { EmptyObject, Simplify } from 'type-fest';
import type {
    UseMutationOptions, UseMutationResult, UseSuspenseQueryResult, UseSuspenseQueryOptions,
    DefaultError } from '@tanstack/react-query';
import type { IsUnknown } from 'ts-essentials';

export const useRestaurantWebMutation = <
    Path extends keyof APIv1RestaurantWeb['ENDPOINT'],
    Result = APIv1RestaurantWeb['ENDPOINT'][Path]['result'],
    // @ts-expect-error
    Data = APIv1RestaurantWeb['ENDPOINT'][Path]['data'],
    Query = IsUnknown<APIv1RestaurantWeb['ENDPOINT'][Path]['query']> extends true ? undefined : APIv1RestaurantWeb['ENDPOINT'][Path]['query'],
    // @ts-expect-error
    Params = IsUnknown<APIv1RestaurantWeb['ENDPOINT'][Path]['params']> extends true ? undefined : APIv1RestaurantWeb['ENDPOINT'][Path]['params'],
    Input = (undefined extends Data ? Record<never, never> : { data: Data }) & ({ headers?: Record<string, string> } | Record<never, never>) & (undefined extends Query ? Record<never, never> : { query: Query }) & (undefined extends Params ? Record<never, never> : { params: Params }),
>(
    path: Path,
    options: UseMutationOptions<Result, unknown, Input, unknown> = {},
): UseMutationResult<Result | undefined, unknown, Input, unknown> => {
    // const { i18n } = useTranslation();
    // const { enqueueSnackbar } = useSnackbar();
    // const { t } = useTranslation('api.client');
    const enqueueSnackbar: (message: any, options: any) => undefined = () => undefined;
    const t = (message: string) => message;

    return useMutation<Result | undefined, unknown, Input>(
        {
            // @ts-expect-error
            mutationFn: async ({ data, params, query, headers: inputHeaders }: Input, headers?: Record<string, string>): Result | undefined => {
                const result = await fetchApi(
                    path,
                    {
                        // @ts-expect-error
                        data,
                        params,
                        query,
                    },
                    {
                        headers: {
                        // ['accept-language']: i18n.language,
                            ...(headers ?? {}),
                            ...(inputHeaders ?? {}),
                        },
                    }
                );

                // log validation error
                if (
                    // @ts-expect-error
                    result.httpStatus === 400
                    // @ts-expect-error
                    && ['scope', 'query', 'params', 'data', 'result'].includes(result.result?.type ?? '')
                ) {
                    logger.error(createInlineError('ApiClient', 'Request validation error', {
                        extra: {
                            response: JSON.stringify(result.result),
                            statusCode: result.httpStatus,
                            path,
                        },
                    }));
                }

                // we want to show message from server !
                if (result.httpStatus >= 300) {
                    // @ts-expect-error
                    if (result.httpStatus === 401) {
                        enqueueSnackbar(t('warning.message.sessionExpired'), { variant: 'warning' });

                        // @ts-expect-error
                    } else if (result.httpStatus === 413) {
                        enqueueSnackbar(t('warning.message.tooLarge'), { variant: 'warning' });

                        // @ts-expect-error
                    } else if (result.httpStatus === 403) {
                        enqueueSnackbar(t('error.message.accessForbidden'), { variant: 'error' });

                    } else {
                        // Default message is for unexpected returns. For example from load balancer etc...
                        enqueueSnackbar(result.result ?? t('error.message.unexpected'), { variant: 'error' });
                    }

                    return undefined;
                }

                // @ts-expect-error
                return result;
            },
            ...options,
        },
    );
};

export const useRestaurantWebQuery = <
    Path extends keyof APIv1RestaurantWeb['ENDPOINT'],
    Result = APIv1RestaurantWeb['ENDPOINT'][Path]['result'],
    Query = IsUnknown<APIv1RestaurantWeb['ENDPOINT'][Path]['query']> extends true ? undefined : APIv1RestaurantWeb['ENDPOINT'][Path]['query'],
    // @ts-expect-error
    Params = IsUnknown<APIv1RestaurantWeb['ENDPOINT'][Path]['params']> extends true ? undefined : APIv1RestaurantWeb['ENDPOINT'][Path]['params'],
    // @ts-expect-error
    Data = IsUnknown<APIv1RestaurantWeb['ENDPOINT'][Path]['data']> extends true ? undefined : APIv1RestaurantWeb['ENDPOINT'][Path]['data'],
>(
    path: Path,
    data: Simplify<(undefined extends Query ? EmptyObject : { query: Query }) & (undefined extends Params ? EmptyObject : { params: Params }) & (undefined extends Data ? EmptyObject : { data: Data })>,
    options: Omit<UseSuspenseQueryOptions<Result>, 'queryFn' | 'queryKey'> = {}
): UseSuspenseQueryResult<Result, DefaultError> => {
    // const { i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    // @TODO
    // const enqueueSnackbar: (message: any, options: any) => undefined = () => undefined;
    const t = (message: string) => message;
    // @TODO
    // const { t } = useTranslation('api.client');

    const {
        queryFn,
        queryKey,
    } = prepareApiCall(path, data, {
        headers: {
            // ['accept-language']: i18n.language,
        },
    });

    return useSuspenseQuery<Result, DefaultError, Result>(
        {
            queryKey,
            queryFn: async ({ signal }): Promise<Result> => {
                const result = await queryFn({ signal });

                // log validation error
                if (
                    // @ts-expect-error
                    result.httpStatus === 400
                    // @ts-expect-error
                    && ['scope', 'query', 'params', 'data', 'result'].includes(result.result?.type ?? '')
                ) {
                    logger.error(createInlineError('ApiClient', 'Request validation error', {
                        extra: {
                            response: JSON.stringify(result.result),
                            statusCode: result.httpStatus,
                            path,
                        },
                    }));
                }

                // we want to show message from server !
                if (result.httpStatus >= 300) {
                    // @ts-expect-error
                    if (result.httpStatus === 401) {
                        enqueueSnackbar(t('warning.message.sessionExpired'), { variant: 'warning' });

                        // @ts-expect-error
                    } else if (result.httpStatus === 413) {
                        enqueueSnackbar(t('warning.message.tooLarge'), { variant: 'warning' });

                        // @ts-expect-error
                    } else if (result.httpStatus === 403) {
                        enqueueSnackbar(t('error.message.accessForbidden'), { variant: 'error' });

                    } else {
                        // Default message is for unexpected returns. For example from load balancer etc...
                        // @ts-expect-error
                        enqueueSnackbar(result.result?.message ?? t('error.message.unexpected'), { variant: 'error' });
                    }

                    throw createInlineError('ApiClient', 'Unexpected statusCode', {
                        extra: {
                            response: JSON.stringify(result.result),
                            statusCode: result.httpStatus,
                            path,
                        },
                    });
                }

                // @ts-expect-error
                return result;
            },
            ...options,
        }
    );
};
