import React from 'react';
import Image from 'next/image';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import QerkoLogoFull from 'restaurant-v3/src/icons/qerko-logo-full.svg';

export const poweredByQerko =
    (
        <MuiLink
            href="https://www.qerko.com"
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                }}
            >
                <Typography
                    sx={{
                        color: 'neutral400.main',
                        whiteSpace: 'nowrap',
                    }}
                >
                    Powered by
                </Typography>
                <Image
                    alt="Qerko Logo"
                    src={QerkoLogoFull}
                />
            </Box>
        </MuiLink>
    );
