import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import badIcon from 'src/shared/feedbackImg/20.svg';
import canBeBetterIcon from 'src/shared/feedbackImg/40.svg';
import okIcon from 'src/shared/feedbackImg/60.svg';
import goodIcon from 'src/shared/feedbackImg/80.svg';
import perfectIcon from 'src/shared/feedbackImg/100.svg';

import type { APIv1RestaurantWeb } from 'src/shared-interface/ApiRestaurantWebInterface';

type FeedbackAPIResponse = Extract<APIv1RestaurantWeb['ENDPOINT']['GET:/feedbacks/pinned-feedbacks']['result']['result'], {success: true}>['payload']['feedbacks'][number];

const feedbackImg = (rate: number) => {
    switch (true) {
        case (rate === 0): {
            return 'N/A';
        }
        case (rate < 21): {
            return badIcon.src;
        }
        case (rate < 41): {
            return canBeBetterIcon.src;
        }
        case (rate < 61): {
            return okIcon.src;
        }
        case (rate < 81): {
            return goodIcon.src;
        }
        case (rate <= 100): {
            return perfectIcon.src;
        }
        default:
            return 'N/A';
    }
};

const formatCustomerName = (name: FeedbackAPIResponse['name'], surname: FeedbackAPIResponse['surname']) =>
    `${name} ${(function () {
        if (surname.length > 1) {
            return `${surname[0]}.`;
        }
        return surname;
    })()}`;

type FeedbackIconProps = {
    rate: FeedbackAPIResponse['rate'];
};

const FeedbackIcon: React.FC<FeedbackIconProps> = ({ rate }) => (
    <Box
        sx={{
            backgroundImage: `url('${feedbackImg(rate !== null ? rate * 20 : 0)}')`,
            backgroundPosition: 'center',
            height: '2.75rem',
            width: '2.75rem',
        }}
    />
);

type Props = {
    feedback: FeedbackAPIResponse;
};

export const Feedback: React.FC<Props> = ({ feedback }) => {
    const { createdAt, name, surname, rate, feedback: feedbackText } = feedback;
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.25,
            width: '100%',
            height: '180px',
            borderRadius: 5,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'neutral200.main',
            padding: 3,
        }}
        >
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    color: 'neutral400.main',
                    gap: 1.25,
                }}
            >
                <FeedbackIcon
                    rate={rate}
                />
                <Typography
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    {formatCustomerName(name, surname)}
                </Typography>
                <Typography>
                    {/* TODO - double check localization with Matej */}
                    {createdAt.toLocaleDateString()}
                </Typography>
            </Box>
            <Typography
                sx={{
                    overflow: 'hidden',
                    textOverflow: '" [..]"',
                }}
                variant="h6"
            >
                {feedbackText}
            </Typography>
        </Box>
    );
};

