'use client';

import React from 'react';
import MuiLink from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NonEmptyStringSchema, PositiveIntegerSchema } from 'src/shared/types';
import { useRestaurantInfoQuery } from 'restaurant-v3/src/hooks/queries/useRestaurantInfoQuery';
import { useRestaurantDomain } from 'restaurant-v3/src/hooks/queries/useRestaurantDomain';
import { resolveReservationLink } from 'restaurant-v3/src/utils/reservationLink';

import { BlurredRestaurantImageArea } from './BlurredRestaurantImageArea';
import { Logo } from './Logo';
import { VisualButton } from './VisualButton';

import type { HttpUrl, NonEmptyString } from 'src/shared/types';

type Props = {
    background: Parameters<typeof BlurredRestaurantImageArea>[0]['background'];
    logo: {
        imageUrl: HttpUrl | null;
    };
    text: string;
    link: {
        href: HttpUrl;
        label: NonEmptyString;
    };
};

const reservationLinkLogoSize = PositiveIntegerSchema.parse(80);

export const ReservationLink: React.FC = () => {
    const restaurantDomain = useRestaurantDomain();
    const { data: restaurantInfoResult } = useRestaurantInfoQuery({ restaurantDomain });

    if (!restaurantInfoResult.result.success) {
        return null;
    }
    return (
        <RawReservationLink
            background={restaurantInfoResult.result.payload.restaurantImage !== null ? {
                type: 'image',
                url: restaurantInfoResult.result.payload.restaurantImage,
            } : {
                type: 'empty',
            }}
            link={{
                href: resolveReservationLink(restaurantInfoResult.result.payload),
                // TODO - localization
                label: NonEmptyStringSchema.parse('Rezervovat stůl'),
            }}
            logo={{
                imageUrl: restaurantInfoResult.result.payload.restaurantLogo,
            }}
            // TODO - localization
            text="Rezervujte si své místo jednoduše z mobilu"
        />
    );
};

const RawReservationLink: React.FC<Props> = ({ background, link, logo, text }) => {
    return (
        <BlurredRestaurantImageArea
            background={background}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Logo
                    imageUrl={logo.imageUrl}
                    size={reservationLinkLogoSize}
                    variant="rounded"
                />
                <Typography
                    sx={{
                        textAlign: 'center',
                        color: 'white.main',
                        paddingY: 4,
                    }}
                    variant="h3"
                >
                    {text}
                </Typography>
                <MuiLink href={link.href}>
                    <VisualButton
                        label={link.label}
                        variant="secondary"
                    />
                </MuiLink>
            </Box>
        </BlurredRestaurantImageArea>
    );
};
