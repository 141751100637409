'use client';

import React from 'react';
import Box from '@mui/material/Box';
import { useRestaurantInfoQuery } from 'restaurant-v3/src/hooks/queries/useRestaurantInfoQuery';
import { useRestaurantWebQuery } from 'restaurant-v3/src/hooks/useApi';
import { useRestaurantDomain } from 'restaurant-v3/src/hooks/queries/useRestaurantDomain';

import { HeroSectionBrowser } from './HeroSectionBrowser';
import { HeroSectionMobile } from './HeroSectionMobile';

export const HeroSection: React.FC = () => {
    const restaurantDomain = useRestaurantDomain();
    const { data: restaurantInfoResult } = useRestaurantInfoQuery({ restaurantDomain });
    const { data: menuItemSlideshowResult } = useRestaurantWebQuery(
        'GET:/menu-item/upsell-items-slideshow',
        {
            query: {
                restaurantDomain,
            },
        },
        {}
    );
    if (
        !menuItemSlideshowResult.result.success ||
        !restaurantInfoResult.result.success
    ) {
        return null;
    }
    return (
        <>
            <Box
                component="section"
                sx={(theme) => ({
                    [theme.breakpoints.up('md')]: {
                        display: 'none',
                    },
                    [theme.breakpoints.down('md')]: {
                        display: 'block',
                    },
                })}
            >
                <HeroSectionMobile
                    menuItemSlideshow={menuItemSlideshowResult.result.payload.menuItems}
                    restaurantDescription={restaurantInfoResult.result.payload.restaurantProfile.aboutRestaurant}
                    restaurantId={restaurantInfoResult.result.payload.restaurant.id}
                    restaurantImage={{
                        url: restaurantInfoResult.result.payload.restaurantImage,
                        animationEnabled: true,
                    }}
                    restaurantName={restaurantInfoResult.result.payload.restaurant.name}
                    takeaway={restaurantInfoResult.result.payload.takeaway}
                />
            </Box>
            <Box
                component="section"
                sx={(theme) => ({
                    [theme.breakpoints.down('md')]: {
                        display: 'none',
                    },
                    [theme.breakpoints.up('md')]: {
                        display: 'block',
                    },
                })}
            >
                <HeroSectionBrowser
                    menuItemSlideshow={menuItemSlideshowResult.result.payload.menuItems}
                    restaurantDescription={restaurantInfoResult.result.payload.restaurantProfile.aboutRestaurant}
                    restaurantId={restaurantInfoResult.result.payload.restaurant.id}
                    restaurantImage={{
                        url: restaurantInfoResult.result.payload.restaurantImage,
                        animationEnabled: true,
                    }}
                    restaurantName={restaurantInfoResult.result.payload.restaurant.name}
                    takeaway={restaurantInfoResult.result.payload.takeaway}
                />
            </Box>
        </>
    );
};
