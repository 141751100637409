import React from 'react';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { VisualButton } from 'restaurant-v3/src/components/VisualButton';

import { poweredByQerko } from './poweredByQerko';

import type { TakeawayComponentProps } from './types';

export const TakeawayOrderMobileButton: React.FC<TakeawayComponentProps> = ({ takeawayUrl }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                gap: 2.5,
                height: '100%',
                paddingY: 3,
                paddingX: 1.75,
                borderRadius: 5,
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'neutral200.main',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                }}
            >
                <Typography
                    sx={{
                        whiteSpace: 'nowrap',
                    }}
                    variant="h6"
                >
                    Jídlo s sebou?
                </Typography>
                {poweredByQerko}
            </Box>
            <Box>
                <MuiLink
                    href={takeawayUrl}
                >
                    <VisualButton
                        label="Objednejte si přes Qerko."
                        variant="primary"
                    />
                </MuiLink>
            </Box>
        </Box>
    );
};
