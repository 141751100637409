'use client';

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMediaQuery, type Theme } from '@mui/material';
import { useRestaurantWebQuery } from 'restaurant-v3/src/hooks/useApi';
import { useRestaurantDomain } from 'restaurant-v3/src/hooks/queries/useRestaurantDomain';

import { Feedback } from './Feedback';
import { HorizontalScrollArea } from './HorizontalScrollArea';

type Props = {
    title: string;
    feedbacks: Array<Parameters<typeof Feedback>[0]['feedback']>;
};

export const FeedbackSection: React.FC = () => {
    const restaurantDomain = useRestaurantDomain();
    const { data: pinnedFeedbacksResult } = useRestaurantWebQuery(
        'GET:/feedbacks/pinned-feedbacks',
        {
            query: {
                restaurantDomain,
            },
        },
        {}
    );

    if (
        !pinnedFeedbacksResult.result.success
    ) {
        return null;
    }
    return (
        <RawFeedbackSection
            feedbacks={pinnedFeedbacksResult.result.payload.feedbacks}
            title="Co říkají naši hosté"
        />
    );
};

const RawFeedbackSection: React.FC<Props> = ({ feedbacks, title }) => {
    const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
    const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.only('sm'));
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.only('md'));
    const shouldHandleOverflowWithHorizontalScroll = isXs || isSm;
    const shouldHandleOverflowWithFlexWrap = !shouldHandleOverflowWithHorizontalScroll;
    if (feedbacks.length === 0) {
        return null;
    }
    const feedbacksJsx = feedbacks.map((feedback, index) => (
        <Box
            key={index}
            sx={(function () {
                if (shouldHandleOverflowWithFlexWrap) {
                    switch (true) {
                        case isMd:
                            return {
                                width: '45%',
                            };
                        default:
                            return {
                                width: '30%',
                            };
                    }
                }
                switch (true) {
                    case isXs:
                        return {
                            minWidth: '90%',
                        };
                    default:
                        return {
                            minWidth: '60%',
                        };
                }
            })()}
        >
            <Feedback
                feedback={feedback}
            />
        </Box>
    ));
    return (
        <Box
            component="section"
            sx={{
                display: 'flex',
                gap: 5,
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                paddingY: 10,
                paddingX: 2,
            }}
        >
            <Typography
                sx={{
                    color: 'neutral900.main',
                    // Note(samuel) - width 100% and textAlign: center is a workaround instead of using alignItems:center, which breaks horizontal scroll
                    width: '100%',
                    textAlign: 'center',
                }}
                variant="h3"
            >
                {title}
            </Typography>
            {shouldHandleOverflowWithHorizontalScroll && (
                <HorizontalScrollArea
                    transitionBackgroundSize={isSm ? 20 : 40}
                >
                    {feedbacksJsx}
                </HorizontalScrollArea>
            )}
            {shouldHandleOverflowWithFlexWrap && (
                <Box
                    sx={{
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        display: 'flex',
                        gap: 2,
                    }}
                >
                    {feedbacksJsx}
                </Box>
            )}
        </Box>
    );
};
