import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/qerko/qerko/frontend/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/builds/qerko/qerko/frontend/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["DailyMenu"] */ "/builds/qerko/qerko/frontend/restaurant-v3/src/components/DailyMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeedbackSection"] */ "/builds/qerko/qerko/frontend/restaurant-v3/src/components/FeedbackSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroSection"] */ "/builds/qerko/qerko/frontend/restaurant-v3/src/components/HeroSection/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReservationLink"] */ "/builds/qerko/qerko/frontend/restaurant-v3/src/components/ReservationLink.tsx");
